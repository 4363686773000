import React from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

class ResourcesFile extends React.Component {
    render() {
        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
        const { user, resource } = this.props;

        if ((!resource.file && !resource.url) || !resource.isEnabled) {
            return null;
        }

        let hasUrl = resource.url ? true : false;
        let fileUrl = "#";

        let isUserAllowed = false;
        let isGroupAllowed = false;
        let resourceLocked = true; // Default: locked unless logged in and no permissions are set against the resource
        let openAccess = resource?.openAccess ?? false;

        if (openAccess) {
            resourceLocked = false;
        }

        if (user && resource && !openAccess) {
            const accessControlUserGroups =
                resource.userGroupsCustom?.map((group) => group.id) || [];
            const accessControlUsers = new Set(
                resource.usersCustom?.map((user) => user.id) || []
            );

            // Check if access control rules exist
            if (
                accessControlUserGroups.length > 0 ||
                accessControlUsers.size > 0
            ) {
                // Check if the user is directly allowed
                isUserAllowed = accessControlUsers.has(user.id);

                // Check if user belongs to an allowed group
                const userGroupIds =
                    user.accessControlUserGroups?.map((group) => group.id) ||
                    [];
                const accessControlUserGroupsSet = new Set(
                    accessControlUserGroups
                );
                isGroupAllowed = userGroupIds.some((groupId) =>
                    accessControlUserGroupsSet.has(groupId)
                );

                // Unlock the resource if the user or group is allowed
                if (isUserAllowed || isGroupAllowed) {
                    resourceLocked = false;
                }
            } else {
                // No access control rules, resource should be open
                resourceLocked = false;
            }
        }

        if (!resourceLocked) {
            fileUrl = hasUrl
                ? resource.url
                : entryPoint +
                  "/resource/serve-file/" +
                  resource.id +
                  "/" +
                  resource.file.filePath;
        }

        const DynamicElement = fileUrl !== "#" ? "a" : "div";

        const props =
            fileUrl !== "#"
                ? {
                      href: fileUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                  }
                : {
                      onClick: () =>
                          alert(
                              "You do not have permission to view this resource."
                          ),
                  };

        return (
            <Col
                key={`resource-${this.props.index}`}
                className="p-2 resource resource-fav-container"
                xl="12"
                xs="12"
            >
                <div className="p-3 mb-1">
                    <DynamicElement {...props} className="stretched-link">
                        <FontAwesomeIcon
                            icon={hasUrl ? faGlobe : faFile}
                            className="mr-2"
                        />{" "}
                        {resource.title}
                        {resource.resourceCategory && (
                            <div className="resource-category">
                                <div>{resource.resourceCategory.title}</div>
                            </div>
                        )}
                    </DynamicElement>
                    {this.props.user && (
                        <div
                            className={
                                "resource__user-favourite" +
                                (this.props.isFavourite === true ? " fav" : "")
                            }
                            onClick={() => this.props.onFavourite(resource.id)}
                        >
                            <FontAwesomeIcon icon={faStar} className="mr-2" />
                        </div>
                    )}

                    {resourceLocked && (
                        <div className="fav-resource-item__locked mt-1">
                            <div className="resource-item__locked-inner">
                                <div className="resource-item__locked-icon">
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        color="white"
                                    />
                                </div>
                                <div className="resource-item__locked-text">
                                    Access denied
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        );
    }
}

export default ResourcesFile;

