import React from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/pro-solid-svg-icons";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faCartPlus } from "@fortawesome/pro-solid-svg-icons";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
class ResourcesListItem extends React.Component {
    render() {
        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
        const { user, resource } = this.props;

        if (!resource.file && !resource.url) {
            return null;
        }

        let hasUrl = resource.url ? true : false;
        let fileUrl = "#";

        let isUserAllowed = false;
        let isGroupAllowed = false;
        let resourceLocked = true; // Default: locked unless logged in and no permissions are set against the resource
        let openAccess = resource?.openAccess ?? false;

        if (openAccess) {
            resourceLocked = false;
        }

        if (user && resource && !openAccess) {
            // Extract allowed users and groups
            const accessControlUserGroups =
                resource.userGroupsCustom?.map((group) => group.id) || [];
            const accessControlUsers = new Set(
                resource.usersCustom?.map((user) => user.id) || []
            );

            // Check if access control rules exist
            if (
                accessControlUserGroups.length > 0 ||
                accessControlUsers.size > 0
            ) {
                // Check if the user is directly allowed
                isUserAllowed = accessControlUsers.has(user.id);

                // Check if user belongs to an allowed group
                const userGroupIds =
                    user.accessControlUserGroups?.map((group) => group.id) ||
                    [];
                const accessControlUserGroupsSet = new Set(
                    accessControlUserGroups
                );
                isGroupAllowed = userGroupIds.some((groupId) =>
                    accessControlUserGroupsSet.has(groupId)
                );

                // Unlock the resource if the user or group is allowed
                if (isUserAllowed || isGroupAllowed) {
                    resourceLocked = false;
                }
            } else {
                // No access control rules, resource should be open
                resourceLocked = false;
            }
        }

        if (!resourceLocked) {
            fileUrl = hasUrl
                ? resource.url
                : entryPoint +
                  "/resource/serve-file/" +
                  resource.id +
                  "/" +
                  resource.file.filePath;
        }

        let coverImage = false;

        if (resource.coverImage && resource.coverImage.filePath) {
            coverImage = entryPoint + "/media/" + resource.coverImage.filePath;
        }

        const DynamicElement = fileUrl !== "#" ? "a" : "div";

        const props =
            fileUrl !== "#"
                ? {
                      href: fileUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                  }
                : {
                      onClick: () =>
                          alert(
                              "You do not have permission to view this resource."
                          ),
                  };

        return (
            <Col key={`resource-${resource.id}`} xl="4" md="6" xs="12">
                {resourceLocked && (
                    <div className="resource-item__overlay">
                        <FontAwesomeIcon
                            icon={faLock}
                            className="overlay-lock-icon"
                        />
                    </div>
                )}
                <div className="filter-page-resource-item">
                    <div
                        className={
                            "resource-item__image" +
                            (coverImage ? " has-image" : "")
                        }
                    >
                        <DynamicElement {...props}>
                            {coverImage !== false && (
                                <>
                                    <img src={coverImage} />
                                </>
                            )}

                            {coverImage === false && (
                                <div className="resource-item__no-image">
                                    <FontAwesomeIcon
                                        icon={
                                            hasUrl
                                                ? faGlobe
                                                : faCloudDownloadAlt
                                        }
                                    />
                                </div>
                            )}
                        </DynamicElement>
                    </div>
                    <div className="resource-item__details">
                        <div className="resource-item__text">
                            <div className="resource-item__list-icon">
                                <FontAwesomeIcon
                                    icon={hasUrl ? faGlobe : faFile}
                                />
                            </div>
                            <div className="resource-item__title">
                                <DynamicElement {...props}>
                                    {resource.title}
                                </DynamicElement>
                            </div>
                            <div className="resource-item__category">
                                {resource.resourceCategoryTitle
                                    ? resource.resourceCategoryTitle
                                    : ""}
                            </div>
                        </div>
                        <div className="resource-item__buttons">
                            {this.props.user && (
                                <div
                                    className={
                                        "resource-item__button button-fav" +
                                        (this.props.isFavourite === true
                                            ? " is-fav"
                                            : "")
                                    }
                                    onClick={() =>
                                        this.props.onFavourite(resource.id)
                                    }
                                >
                                    <FontAwesomeIcon icon={faStar} />
                                </div>
                            )}

                            {hasUrl === true && !resourceLocked && (
                                <div className="resource-item__button">
                                    <a href={resource.url} target="_blank">
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </a>
                                </div>
                            )}

                            {hasUrl === false && !resourceLocked && (
                                <>
                                    <div className="resource-item__button">
                                        <DynamicElement {...props}>
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                            />
                                        </DynamicElement>
                                    </div>
                                    {this.props.user && (
                                        <div
                                            className="resource-item__button"
                                            onClick={() =>
                                                this.props.onAddToBasket(
                                                    resource
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faCartPlus}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {resourceLocked && (
                        <div className="resource-item__locked">
                            <div className="resource-item__locked-inner">
                                <div className="resource-item__locked-icon">
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        color="white"
                                    />
                                </div>
                                <div className="resource-item__locked-text">
                                    Access denied
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        );
    }
}

export default ResourcesListItem;

